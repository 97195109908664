import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import './Footer.css';

const { Footer } = Layout;
const { Text } = Typography;

const AppFooter: React.FC = () => {
  return (
    <Footer className="footer">
      <div className="logo-container-center">
        <img src="img/UNIDAD_LOGO_BACKGROUND.png" alt="Logo Gobierno" className="logoTransparencia" />
        <img src="img/rti.webp" alt="RTI Logo" className="logo-footer" />
        <img src="img/usaid.png" alt="USAID Logo" className="logo-footer" />
      </div>
      <Row className="footer-content" justify="center">
        <Col xs={24} md={12}>
          <Text className="footer-text">© 2024 Gobierno de la República de Honduras. Todos los derechos reservados.</Text>
        </Col>
        <Col xs={24} md={12} className="footer-contact">
          <Text>Contacto: <a href="mailto:transparencia@gob.hn">transparencia@gob.hn</a> | Tel: (504) 1234-5678</Text>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
