import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Card, Col, Row, Typography, Divider, Collapse } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TeamOutlined, EnvironmentOutlined, HomeOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { Title, Text } = Typography;
const { Panel } = Collapse;

const CentrosList: React.FC = () => {
  const centros = useSelector((state: RootState) => state.listadosDeCentros.centros);
  const navigate = useNavigate();

  const handleCardClick = (codigoSACE: string) => {
    navigate(`/centro/${codigoSACE}`);
  };

  // Agrupar por departamento
  const centrosPorDepartamento = centros.reduce((acc: any, centro) => {
    const { DEPARTAMENTO, MUNICIPIO } = centro;
    if (!acc[DEPARTAMENTO]) {
      acc[DEPARTAMENTO] = [];
    }
    acc[DEPARTAMENTO].push(centro);
    // Ordenar por municipio dentro de cada departamento
    acc[DEPARTAMENTO].sort((a: any, b: any) => a.MUNICIPIO.localeCompare(b.MUNICIPIO));
    return acc;
  }, {});

  const departamentos = Object.keys(centrosPorDepartamento);

  return (
    <div style={{ padding: '40px 20px', backgroundColor: '#f4f6f9' }}>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '40px', color: '#2E3A59', fontSize: '32px', fontWeight: 300 }}>
        Listado de Centros Educativos
      </Title>
      <Collapse bordered={false} style={{ backgroundColor: 'transparent' }} defaultActiveKey={departamentos}>
        {departamentos.map((departamento) => (
          <Panel 
            header={
              <Title level={3} style={{ textAlign: 'center', color: '#1E90FF', fontSize: '24px', margin: 0 }}>
                {departamento}
              </Title>
            } 
            key={departamento}
            style={{ backgroundColor: '#fff', borderRadius: '8px', marginBottom: '20px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', padding: '20px' }}
          >
            <Row gutter={[24, 24]} justify="center">
              {centrosPorDepartamento[departamento].map((centro: any) => (
                <Col xs={24} sm={12} md={8} lg={6} key={centro.CodigoSACE}>
                  <Card
                    hoverable
                    cover={
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', borderRadius: '8px 8px 0 0', height: 180 }}>
                        <img 
                          alt={centro.CentroEducativo} 
                          src={centro.Foto_CENTRO} 
                          style={{ 
                            height: '100%', 
                            width: 'auto', 
                            objectFit: 'cover',
                            transition: 'transform 0.3s ease', 
                          }}
                          className="centro-image"
                        />
                      </div>
                    }
                    onClick={() => handleCardClick(centro.CodigoSACE)}
                    style={{
                      borderRadius: '8px',
                      overflow: 'hidden',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#fff',
                    }}
                    bodyStyle={{ padding: '16px' }}
                    className="centro-card"
                  >
                    <Meta
                      title={
                        <span className="centro-title" style={{ fontSize: '18px', fontWeight: 'bold', color: '#333' }}>
                          {centro.CentroEducativo}
                        </span>
                      }
                      description={
                        <>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <TeamOutlined />
                            <Text>Código SACE: {centro.CodigoSACE}</Text>
                          </div>
                          <Divider style={{ margin: '10px 0' }} />
                          <Text><EnvironmentOutlined /> Departamento: {centro.DEPARTAMENTO}</Text>
                          <Divider style={{ margin: '10px 0' }} />
                          <Text><HomeOutlined /> Municipio: {centro.MUNICIPIO}</Text>
                          <Divider style={{ margin: '10px 0' }} />
                          <Text><HomeOutlined /> Aldea: {centro.ALDEA}</Text>
                          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <img 
                              alt="Logo del Centro" 
                              src={centro.Logo_CENTRO} 
                              style={{ height: 50, objectFit: 'contain', borderRadius: '5px' }}
                            />
                          </div>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Panel>
        ))}
      </Collapse>
      <style>
        {`
        .centro-card:hover {
          transform: scale(1.02);
          box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
        }
        .centro-card:hover .centro-image {
          transform: scale(1.05);
        }
        .centro-title {
          transition: color 0.3s ease;
        }
        .centro-card:hover .centro-title {
          color: #1E90FF;
        }
        `}
      </style>
    </div>
  );
}

export default CentrosList;
