import { configureStore } from '@reduxjs/toolkit';
import escuelasReducer from './slices/escuelasSlice';
import listadosDeCentrosReducer from './slices/listadosDeCentrosSlice';

const store = configureStore({
  reducer: {
    escuelas: escuelasReducer,
    listadosDeCentros: listadosDeCentrosReducer,
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
