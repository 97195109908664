import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Typography, Card, Empty, Row, Col, Button } from 'antd';
import { SolutionOutlined, DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { RootState } from '../store/store';
import * as XLSX from 'xlsx'; // Import the xlsx library

const { Title, Text } = Typography;

const AdministrativosList: React.FC = () => {
  const { codigoSACE } = useParams<{ codigoSACE: string }>();

  // Obtener la escuela correspondiente
  const escuela = useSelector((state: RootState) =>
    state.listadosDeCentros.centros.find(esc => esc.CodigoSACE === codigoSACE)
  );

  // Filtrar el personal administrativo correspondiente a la escuela seleccionada
  const administrativos = useSelector((state: RootState) =>
    state.listadosDeCentros.personalDeServicioCivil.filter(pers => pers.Codigo_SACE === codigoSACE)
  );

  // Definir las columnas para la tabla
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'Nombre_Empleado',
      key: 'Nombre_Empleado',
      render: (text: string) => (
        <span>
          <UserOutlined style={{ color: 'blue', marginRight: 8 }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Cargo Nominal',
      dataIndex: 'Cargo_Nominal',
      key: 'Cargo_Nominal',
    },
    {
      title: 'Cargo Funcional',
      dataIndex: 'Cargo_Funcional',
      key: 'Cargo_Funcional',
    },
    {
      title: 'Jornada Mixta',
      dataIndex: 'Jornada_Mixta',
      key: 'Jornada_Mixta',
      render: (text: string) => (text ? 'Sí' : 'No'),
    },
    {
      title: 'Jornada Diurna',
      dataIndex: 'Jornada_Diurna',
      key: 'Jornada_Diurna',
      render: (text: string) => (text ? 'Sí' : 'No'),
    },
    {
      title: 'Jornada Nocturna',
      dataIndex: 'Jornada_Nocturna',
      key: 'Jornada_Nocturna',
      render: (text: string) => (text ? 'Sí' : 'No'),
    },
    {
      title: 'Acuerdo Permanente',
      dataIndex: 'Acuerdo_Permanente',
      key: 'Acuerdo_Permanente',
      render: (text: number) => (text === 1 ? 'Sí' : 'No'),
    },
    {
      title: 'Acuerdo Contrato',
      dataIndex: 'Acuerdo_Contrato',
      key: 'Acuerdo_Contrato',
      render: (text: number) => (text === 1 ? 'Sí' : 'No'),
    },
    {
      title: 'Observaciones',
      dataIndex: 'Observaciones',
      key: 'Observaciones',
      render: (text: string) => text || 'N/A',
    },
  ];

  // Función para descargar el listado de administrativos en formato Excel
  const downloadExcel = () => {
    const worksheetData = administrativos.map(admin => ({
      Nombre: admin.Nombre_Empleado,
      'Cargo Nominal': admin.Cargo_Nominal,
      'Cargo Funcional': admin.Cargo_Funcional,
      'Jornada Mixta': admin.Jornada_Mixta ? 'Sí' : 'No',
      'Jornada Diurna': admin.Jornada_Diurna ? 'Sí' : 'No',
      'Jornada Nocturna': admin.Jornada_Nocturna ? 'Sí' : 'No',
      'Acuerdo Permanente': admin.Acuerdo_Permanente === 1 ? 'Sí' : 'No',
      'Acuerdo Contrato': admin.Acuerdo_Contrato === 1 ? 'Sí' : 'No',
      Observaciones: admin.Observaciones || 'N/A',
    }));

    // Crear un nuevo libro de trabajo y agregar los datos
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Administrativos');

    // Generar un archivo Excel
    XLSX.writeFile(workbook, `administrativos_${codigoSACE}.xlsx`);
  };

  return (
    <Card
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
      }}
      bodyStyle={{ padding: '20px' }}
    >
      {escuela ? (
        <>
          <Title
            level={3}
            style={{
              textAlign: 'center',
              color: '#E30613',
              marginBottom: '20px',
              backgroundColor: '#e6f7ff',
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            {escuela.CentroEducativo} - Personal Administrativo
          </Title>

          {/* Mostrar el total de personal administrativo y botón para descargar Excel */}
          <Row justify="space-between" style={{ marginBottom: '20px' }}>
            <Col>
              <Text strong>Total de empleados: {administrativos.length}</Text>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={downloadExcel}
              >
                Descargar Excel
              </Button>
            </Col>
          </Row>

          {administrativos.length > 0 ? (
            <Table
              columns={columns}
              dataSource={administrativos}
              rowKey="ID"
              pagination={{ pageSize: 5 }}
              size="middle"
              scroll={{ x: 'max-content' }}
              bordered
              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
            />
          ) : (
            <Empty description="No hay personal administrativo disponible para esta escuela." style={{ textAlign: 'center' }} />
          )}
        </>
      ) : (
        <Empty description="Escuela no encontrada" style={{ textAlign: 'center' }} />
      )}
    </Card>
  );
};

export default AdministrativosList;
