import React from 'react';
import { Layout } from 'antd';
import './Header.css';

const { Header } = Layout;

const AppHeader: React.FC = () => {
  return (
    <>
      <div className="logo-container-center-header">
        <img src="img/logo.png" alt="Logo Gobierno" className="logo" />
      </div>
      <Header className="header">
        <div className="title-container">
          <h1 className="title">Mural de Transparencia y Rendición de Cuentas</h1>
          <h2 className="subtitle">Educar para refundar con transparencia y rendición de cuentas</h2>
        </div>
      </Header>
    </>
  );
};

export default AppHeader;
