import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Typography, Card, Empty, Row, Col, Button, Modal } from 'antd';
import { ToolOutlined, NumberOutlined, SafetyOutlined, FileDoneOutlined, DollarOutlined, CalendarOutlined, DownloadOutlined } from '@ant-design/icons';
import { RootState } from '../store/store';
import * as XLSX from 'xlsx';

const { Title, Text } = Typography;

interface Inventario {
  Articulo_Descripcion: string;
  Cantidad: number;
  Condicion: string;
  Uso: string;
  Origen_Fondo: string;
  Fecha_Adquisicion: string;
}

const Inventarios: React.FC = () => {
  const { codigoSACE } = useParams<{ codigoSACE: string }>();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Inventario | null>(null);

  // Obtener la escuela correspondiente
  const escuela = useSelector((state: RootState) =>
    state.listadosDeCentros.centros.find(esc => esc.CodigoSACE === codigoSACE)
  );

  // Filtrar el inventario correspondiente a la escuela seleccionada
  const inventario = useSelector((state: RootState) =>
    state.listadosDeCentros.inventario.filter(item => item.Codigo_SACE === codigoSACE)
  );

  // Función para manejar la apertura del modal con el artículo seleccionado
  const openModal = (item: Inventario) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setModalVisible(false);
    setSelectedItem(null);
  };

  // Definir las columnas para la tabla
  const columns = [
    {
      title: 'Artículo',
      dataIndex: 'Articulo_Descripcion',
      key: 'Articulo_Descripcion',
      render: (text: string, record: Inventario) => (
        <span>
          <ToolOutlined style={{ color: 'blue', marginRight: 8 }} />
          <a onClick={() => openModal(record)} style={{ cursor: 'pointer' }}>{text}</a>
        </span>
      ),
    },
    {
      title: 'Cantidad',
      dataIndex: 'Cantidad',
      key: 'Cantidad',
      render: (text: string) => (
        <span>
          <NumberOutlined style={{ color: 'green', marginRight: 8 }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Condición',
      dataIndex: 'Condicion',
      key: 'Condicion',
      render: (text: string) => (
        <span>
          <SafetyOutlined style={{ color: 'orange', marginRight: 8 }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Uso',
      dataIndex: 'Uso',
      key: 'Uso',
      render: (text: string) => (
        <span>
          <FileDoneOutlined style={{ color: 'purple', marginRight: 8 }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Fuente de Financiamiento',
      dataIndex: 'Origen_Fondo',
      key: 'Origen_Fondo',
      render: (text: string) => (
        <span>
          <DollarOutlined style={{ color: 'red', marginRight: 8 }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Fecha de Adquisición',
      dataIndex: 'Fecha_Adquisicion',
      key: 'Fecha_Adquisicion',
      render: (text: string) => (
        <span>
          <CalendarOutlined style={{ color: 'blue', marginRight: 8 }} />
          {text}
        </span>
      ),
    },
  ];

  // Función para descargar el inventario en formato Excel
  const downloadExcel = () => {
    const worksheetData = inventario.map(item => ({
      Artículo: item.Articulo_Descripcion,
      Cantidad: item.Cantidad,
      Condición: item.Condicion,
      Uso: item.Uso,
      'Fuente de Financiamiento': item.Origen_Fondo,
      'Fecha de Adquisición': item.Fecha_Adquisicion,
    }));

    // Crear un nuevo libro de trabajo y agregar los datos
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventario');

    // Generar un archivo Excel
    XLSX.writeFile(workbook, `inventario_${codigoSACE}.xlsx`);
  };

  return (
    <Card
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
      }}
      bodyStyle={{ padding: '20px' }}
    >
      {escuela ? (
        <>
          <Title
            level={3}
            style={{
              textAlign: 'center',
              color: '#E30613',
              marginBottom: '20px',
              backgroundColor: '#e6f7ff',
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            {escuela.CentroEducativo} - Inventario
          </Title>

          {/* Mostrar el total de artículos y botón para descargar Excel */}
          <Row justify="space-between" style={{ marginBottom: '20px' }}>
            <Col>
              <Text strong>Total de artículos: {inventario.length}</Text>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={downloadExcel}
              >
                Descargar Excel
              </Button>
            </Col>
          </Row>

          {inventario.length > 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={inventario}
                rowKey="Articulo_Descripcion"
                pagination={{ pageSize: 5 }}
                size="middle"
                scroll={{ x: 'max-content' }}
                bordered
                style={{ backgroundColor: '#fff', borderRadius: '5px' }}
              />
              {/* Modal to display details */}
              <Modal
                visible={modalVisible}
                footer={null}
                onCancel={closeModal}
                width={800}
              >
                {selectedItem && (
                  <div>
                    <p><strong>Artículo:</strong> {selectedItem.Articulo_Descripcion}</p>
                    <p><strong>Cantidad:</strong> {selectedItem.Cantidad}</p>
                    <p><strong>Condición:</strong> {selectedItem.Condicion}</p>
                    <p><strong>Uso:</strong> {selectedItem.Uso}</p>
                    <p><strong>Fuente de Financiamiento:</strong> {selectedItem.Origen_Fondo}</p>
                    <p><strong>Fecha de Adquisición:</strong> {selectedItem.Fecha_Adquisicion}</p>
                  </div>
                )}
              </Modal>
            </>
          ) : (
            <Empty description="No hay inventario disponible para esta escuela." style={{ textAlign: 'center' }} />
          )}
        </>
      ) : (
        <Empty description="Escuela no encontrada" style={{ textAlign: 'center' }} />
      )}
    </Card>
  );
};

export default Inventarios;
