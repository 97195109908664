import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Typography, Card, Empty, Row, Col, Button, Modal } from 'antd';
import { FileImageOutlined, DownloadOutlined } from '@ant-design/icons';
import { RootState } from '../store/store';
import * as XLSX from 'xlsx'; // Import the xlsx library

const { Title, Text } = Typography;

const InformesFinancieros: React.FC = () => {
  const { codigoSACE } = useParams<{ codigoSACE: string }>();
  
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  // Obtener la escuela correspondiente
  const escuela = useSelector((state: RootState) =>
    state.listadosDeCentros.centros.find(esc => esc.CodigoSACE === codigoSACE)
  );

  // Filtrar los informes financieros correspondientes a la escuela seleccionada
  const informesFinancieros = useSelector((state: RootState) =>
    state.listadosDeCentros.informesFinancieros.filter(informe => informe.Codigo_SACE === codigoSACE)
  );

  // Función para manejar la apertura del modal con la imagen seleccionada
  const openModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setModalVisible(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setModalVisible(false);
    setSelectedImage(null);
  };

  // Definir las columnas para la tabla
  const columns = [
    {
      title: 'Liquidación Matrícula Gratis',
      dataIndex: 'Imagen_Liquidacion_Matricula_Gratis',
      key: 'Imagen_Liquidacion_Matricula_Gratis',
      render: (text: string) => (
        <span>
          <FileImageOutlined style={{ color: 'blue', marginRight: 8 }} />
          <a onClick={() => openModal(text)} style={{ cursor: 'pointer' }}>Ver Liquidación</a>
        </span>
      ),
    },
    {
      title: 'Liquidación Fondos Propios',
      dataIndex: 'Imagen_Liquidacion_Fondos_Propios',
      key: 'Imagen_Liquidacion_Fondos_Propios',
      render: (text: string) => (
        <span>
          <FileImageOutlined style={{ color: 'green', marginRight: 8 }} />
          <a onClick={() => openModal(text)} style={{ cursor: 'pointer' }}>Ver Liquidación</a>
        </span>
      ),
    },
  ];

  // Función para descargar el listado de informes financieros en formato Excel
  const downloadExcel = () => {
    const worksheetData = informesFinancieros.map(informe => ({
      'Liquidación Matrícula Gratis': informe.Imagen_Liquidacion_Matricula_Gratis,
      'Liquidación Fondos Propios': informe.Imagen_Liquidacion_Fondos_Propios,
    }));

    // Crear un nuevo libro de trabajo y agregar los datos
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Informes Financieros');

    // Generar un archivo Excel
    XLSX.writeFile(workbook, `informes_financieros_${codigoSACE}.xlsx`);
  };

  return (
    <Card
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
      }}
      bodyStyle={{ padding: '20px' }}
    >
      {escuela ? (
        <>
          <Title
            level={3}
            style={{
              textAlign: 'center',
              color: '#E30613',
              marginBottom: '20px',
              backgroundColor: '#e6f7ff',
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            {escuela.CentroEducativo} - Informes Financieros
          </Title>

          {/* Mostrar el total de informes financieros y botón para descargar Excel */}
          <Row justify="space-between" style={{ marginBottom: '20px' }}>
            <Col>
              <Text strong>Total de informes: {informesFinancieros.length}</Text>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={downloadExcel}
              >
                Descargar Excel
              </Button>
            </Col>
          </Row>

          {informesFinancieros.length > 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={informesFinancieros}
                rowKey="Codigo_SACE"
                pagination={{ pageSize: 5 }}
                size="middle"
                scroll={{ x: 'max-content' }}
                bordered
                style={{ backgroundColor: '#fff', borderRadius: '5px' }}
              />
              {/* Modal to display image */}
              <Modal
                visible={modalVisible}
                footer={null}
                onCancel={closeModal}
                width={800}
              >
                {selectedImage && <img src={selectedImage} alt="Informe Financiero" style={{ width: '100%' }} />}
              </Modal>
            </>
          ) : (
            <Empty description="No hay informes financieros disponibles para esta escuela." style={{ textAlign: 'center' }} />
          )}
        </>
      ) : (
        <Empty description="Escuela no encontrada" style={{ textAlign: 'center' }} />
      )}
    </Card>
  );
};

export default InformesFinancieros;
