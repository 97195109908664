import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Typography, Card, Empty, Row, Col, Button, Modal } from 'antd';
import { UserOutlined, TeamOutlined, DownloadOutlined } from '@ant-design/icons';
import { RootState } from '../store/store';
import * as XLSX from 'xlsx'; // Import the xlsx library

const { Title, Text } = Typography;

interface Docente {
  Nombre_Docente: string;
  Grado: string;
  Seccion: string;
  Matricula_Niñas: number;
  Matricula_Varones: number;
  Matricula_Total: number;
  Jornada_Matutina: boolean;
  Jornada_Vespertina: boolean;
  Jornada_Nocturna: boolean;
  Acuerdo_Permanente: number;
  Acuerdo_Asignado: string;
  Acuerdo_Contrato: number;
  Acuerdo_Interino: number;
  Horas_Clase_Acuerdo: number;
  Observaciones: string;
  Codigo_SACE: string;
  NumeroDocumento: string;
}

const DocentesList: React.FC = () => {
  const { codigoSACE } = useParams<{ codigoSACE: string }>();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDocente, setSelectedDocente] = useState<Docente | null>(null);

  // Obtener la escuela correspondiente
  const escuela = useSelector((state: RootState) =>
    state.listadosDeCentros.centros.find(esc => esc.CodigoSACE === codigoSACE)
  );

  // Filtrar los docentes correspondientes a la escuela seleccionada
  const docentes = useSelector((state: RootState) =>
    state.listadosDeCentros.docentes.filter(doc => doc.Codigo_SACE === codigoSACE)
  );

  // Función para manejar la apertura del modal con el docente seleccionado
  const openModal = (docente: Docente) => {
    setSelectedDocente(docente);
    setModalVisible(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setModalVisible(false);
    setSelectedDocente(null);
  };

  // Definir las columnas para la tabla
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'Nombre_Docente',
      key: 'Nombre_Docente',
      render: (text: string, record: Docente) => (
        <span>
          <UserOutlined style={{ color: 'blue', marginRight: 8 }} />
          <a onClick={() => openModal(record)} style={{ cursor: 'pointer' }}>{text}</a>
        </span>
      ),
    },
    {
      title: 'Grado',
      dataIndex: 'Grado',
      key: 'Grado',
    },
    {
      title: 'Sección',
      dataIndex: 'Seccion',
      key: 'Seccion',
    },
    {
      title: 'Matrícula Niñas',
      dataIndex: 'Matricula_Niñas',
      key: 'Matricula_Niñas',
    },
    {
      title: 'Matrícula Varones',
      dataIndex: 'Matricula_Varones',
      key: 'Matricula_Varones',
    },
    {
      title: 'Matrícula Total',
      dataIndex: 'Matricula_Total',
      key: 'Matricula_Total',
      render: (text: string) => (
        <span>
          <TeamOutlined style={{ color: 'green', marginRight: 8 }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Jornada Matutina',
      dataIndex: 'Jornada_Matutina',
      key: 'Jornada_Matutina',
      render: (text: boolean) => (text ? 'Sí' : 'No'),
    },
    {
      title: 'Jornada Vespertina',
      dataIndex: 'Jornada_Vespertina',
      key: 'Jornada_Vespertina',
      render: (text: boolean) => (text ? 'Sí' : 'No'),
    },
    {
      title: 'Jornada Nocturna',
      dataIndex: 'Jornada_Nocturna',
      key: 'Jornada_Nocturna',
      render: (text: boolean) => (text ? 'Sí' : 'No'),
    },
    {
      title: 'Acuerdo Permanente',
      dataIndex: 'Acuerdo_Permanente',
      key: 'Acuerdo_Permanente',
      render: (text: number) => (text === 1 ? 'Sí' : 'No'),
    },
    {
      title: 'Acuerdo Asignado',
      dataIndex: 'Acuerdo_Asignado',
      key: 'Acuerdo_Asignado',
    },
    {
      title: 'Acuerdo Contrato',
      dataIndex: 'Acuerdo_Contrato',
      key: 'Acuerdo_Contrato',
      render: (text: number) => (text === 1 ? 'Sí' : 'No'),
    },
    {
      title: 'Acuerdo Interino',
      dataIndex: 'Acuerdo_Interino',
      key: 'Acuerdo_Interino',
      render: (text: number) => (text === 1 ? 'Sí' : 'No'),
    },
    {
      title: 'Horas Clase Acuerdo',
      dataIndex: 'Horas_Clase_Acuerdo',
      key: 'Horas_Clase_Acuerdo',
    },
    {
      title: 'Observaciones',
      dataIndex: 'Observaciones',
      key: 'Observaciones',
      render: (text: string) => text || 'N/A',
    },
  ];

  // Función para descargar el listado de docentes en formato Excel
  const downloadExcel = () => {
    const worksheetData = docentes.map(doc => ({
      Nombre: doc.Nombre_Docente,
      Grado: doc.Grado,
      Sección: doc.Seccion,
      'Matrícula Niñas': doc.Matricula_Niñas,
      'Matrícula Varones': doc.Matricula_Varones,
      'Matrícula Total': doc.Matricula_Total,
      'Jornada Matutina': doc.Jornada_Matutina ? 'Sí' : 'No',
      'Jornada Vespertina': doc.Jornada_Vespertina ? 'Sí' : 'No',
      'Jornada Nocturna': doc.Jornada_Nocturna ? 'Sí' : 'No',
      'Acuerdo Permanente': doc.Acuerdo_Permanente === 1 ? 'Sí' : 'No',
      'Acuerdo Asignado': doc.Acuerdo_Asignado,
      'Acuerdo Contrato': doc.Acuerdo_Contrato === 1 ? 'Sí' : 'No',
      'Acuerdo Interino': doc.Acuerdo_Interino === 1 ? 'Sí' : 'No',
      'Horas Clase Acuerdo': doc.Horas_Clase_Acuerdo,
      Observaciones: doc.Observaciones || 'N/A',
    }));

    // Crear un nuevo libro de trabajo y agregar los datos
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Docentes');

    // Generar un archivo Excel
    XLSX.writeFile(workbook, `docentes_${codigoSACE}.xlsx`);
  };

  return (
    <Card
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
      }}
      bodyStyle={{ padding: '20px' }}
    >
      {escuela ? (
        <>
          <Title
            level={3}
            style={{
              textAlign: 'center',
              color: '#E30613',
              marginBottom: '20px',
              backgroundColor: '#e6f7ff',
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            {escuela.CentroEducativo} - Docentes
          </Title>

          {/* Mostrar el total de docentes y botón para descargar Excel */}
          <Row justify="space-between" style={{ marginBottom: '20px' }}>
            <Col>
              <Text strong>Total de docentes: {docentes.length}</Text>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={downloadExcel}
              >
                Descargar Excel
              </Button>
            </Col>
          </Row>

          {docentes.length > 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={docentes}
                rowKey="NumeroDocumento"
                pagination={{ pageSize: 5 }}
                size="middle"
                scroll={{ x: 'max-content' }}
                bordered
                style={{ backgroundColor: '#fff', borderRadius: '5px' }}
              />
              {/* Modal to display teacher details */}
              <Modal
                visible={modalVisible}
                footer={null}
                onCancel={closeModal}
                width={800}
              >
                {selectedDocente && (
                  <div>
                    <p><strong>Nombre:</strong> {selectedDocente.Nombre_Docente}</p>
                    <p><strong>Grado:</strong> {selectedDocente.Grado}</p>
                    <p><strong>Sección:</strong> {selectedDocente.Seccion}</p>
                    <p><strong>Matrícula Niñas:</strong> {selectedDocente.Matricula_Niñas}</p>
                    <p><strong>Matrícula Varones:</strong> {selectedDocente.Matricula_Varones}</p>
                    <p><strong>Matrícula Total:</strong> {selectedDocente.Matricula_Total}</p>
                    <p><strong>Jornada Matutina:</strong> {selectedDocente.Jornada_Matutina ? 'Sí' : 'No'}</p>
                    <p><strong>Jornada Vespertina:</strong> {selectedDocente.Jornada_Vespertina ? 'Sí' : 'No'}</p>
                    <p><strong>Jornada Nocturna:</strong> {selectedDocente.Jornada_Nocturna ? 'Sí' : 'No'}</p>
                    <p><strong>Acuerdo Permanente:</strong> {selectedDocente.Acuerdo_Permanente === 1 ? 'Sí' : 'No'}</p>
                    <p><strong>Acuerdo Contrato:</strong> {selectedDocente.Acuerdo_Contrato === 1 ? 'Sí' : 'No'}</p>
                    <p><strong>Acuerdo Interino:</strong> {selectedDocente.Acuerdo_Interino === 1 ? 'Sí' : 'No'}</p>
                    <p><strong>Horas Clase Acuerdo:</strong> {selectedDocente.Horas_Clase_Acuerdo}</p>
                    <p><strong>Observaciones:</strong> {selectedDocente.Observaciones || 'N/A'}</p>
                  </div>
                )}
              </Modal>
            </>
          ) : (
            <Empty description="No hay docentes disponibles para esta escuela." style={{ textAlign: 'center' }} />
          )}
        </>
      ) : (
        <Empty description="Escuela no encontrada" style={{ textAlign: 'center' }} />
      )}
    </Card>
  );
};

export default DocentesList;
